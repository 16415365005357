<template>
  <g>
    <svg:style>
      .cls-10, .cls-11, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-10, .cls-11, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke-linecap: square;
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-5 {
        fill: url(#glass-pattern);
      }

      .cls-6 {
        fill: url(#inox-gradient-2);
      }

      .cls-7 {
        fill: url(#glass-pattern);
      }

      .cls-8 {
        fill: url(#inox-gradient-3);
      }

      .cls-9 {
        fill: url(#glass-pattern);
      }

      .cls-10 {
        fill: url(#inox-gradient-4);
      }

      .cls-11 {
        fill: url(#glass-pattern);
      }

      .Solid { fill: {{ doorSolidColor }} }
      .Molding-L { fill: url(#Molding-L); } /* left */
      .Molding-T { fill: url(#Molding-T); } /* top */
      .Molding-R { fill: url(#Molding-R); } /* right */
      .Molding-B { fill: url(#Molding-B); } /* bottom */
    </svg:style>

    <linearGradient id="handle-gradient"
                    :x1="doorLeftWidth1 + 11.16"
                    :y1="doorTopHeight1 + 148.47"
                    :x2="doorLeftWidth1 + 16.75"
                    :y2="doorTopHeight1 + 148.47"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient id="inox-gradient"
                    :x1="doorLeftWidth1 + 69.92"
                    :y1="doorTopHeight1 + 78.86"
                    :x2="doorLeftWidth1 + 69.92"
                    :y2="doorTopHeight1 + 50.77"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="glass-gradient"
                    :x1="doorLeftWidth1 + 69.92"
                    :y1="doorTopHeight1 + 74.06"
                    :x2="doorLeftWidth1 + 69.92"
                    :y2="doorTopHeight1 + 55.81"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient id="inox-gradient-2"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth1 + 69.92"
                    :y1="doorTopHeight1 + 134.54"
                    :x2="doorLeftWidth1 + 69.92"
                    :y2="doorTopHeight1 + 106.7"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="glass-gradient-2"
                    data-name="glass-gradient"
                    :x1="doorLeftWidth1 + 69.92"
                    :y1="doorTopHeight1 + 129.74"
                    :x2="doorLeftWidth1 + 69.92"
                    :y2="doorTopHeight1 + 111.5"
                    xlink:href="#glass-gradient"/>
    <linearGradient id="inox-gradient-3"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth1 + 69.92"
                    :y1="doorTopHeight1 + 246.16"
                    :x2="doorLeftWidth1 + 69.92"
                    :y2="doorTopHeight1 + 218.08"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="glass-gradient-3"
                    data-name="glass-gradient"
                    :x1="doorLeftWidth1 + 69.92"
                    :y1="doorTopHeight1 + 241.12"
                    :x2="doorLeftWidth1 + 69.92"
                    :y2="doorTopHeight1 + 222.88"
                    xlink:href="#glass-gradient"/>
    <linearGradient id="inox-gradient-4"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth1 + 69.92"
                    :y1="doorTopHeight1 + 190.23"
                    :x2="doorLeftWidth1 + 69.92"
                    :y2="doorTopHeight1 + 162.39"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="glass-gradient-4"
                    data-name="glass-gradient"
                    :x1="doorLeftWidth1 + 69.92"
                    :y1="doorTopHeight1 + 185.43"
                    :x2="doorLeftWidth1 + 69.92"
                    :y2="doorTopHeight1 + 167.19"
                    xlink:href="#glass-gradient"/>
    <linearGradient id="Molding-L" data-name="Molding" x1="0" y1="0" x2="100%" y2="0">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>
    <linearGradient id="Molding-T" data-name="Molding" x1="0" y1="0" x2="0" y2="100%">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>
    <linearGradient id="Molding-R" data-name="Molding" x1="100%" y1="0" x2="0" y2="0">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>
    <linearGradient id="Molding-B" data-name="Molding" x1="0" y1="100%" x2="0" y2="0">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>
    <g id="H11">
      <rect id="inox"
            v-if="showInox"
            class="cls-4"
            :x="doorLeftWidth1 + 35.01"
            :y="doorTopHeight1 + 50.77"
            width="69.83"
            height="28.08"/>
      <rect id="glass"
            class="cls-5"
            :x="doorLeftWidth1 + 39.92"
            :y="doorTopHeight1 + 55.81"
            width="59.99"
            height="18.24"/>
      <rect id="inox-2"
            v-if="showInox"
            data-name="inox"
            class="cls-6"
            :x="doorLeftWidth1 + 35.01"
            :y="doorTopHeight1 + 106.7"
            width="69.83"
            height="27.84"/>
      <rect id="glass-2"
            data-name="glass"
            class="cls-7"
            :x="doorLeftWidth1 + 39.92"
            :y="doorTopHeight1 + 111.5"
            width="59.99"
            height="18.24"/>
      <rect id="inox-3"
            v-if="showInox"
            data-name="inox"
            class="cls-8"
            :x="doorLeftWidth1 + 35.01"
            :y="doorTopHeight1 + 218.08"
            width="69.83"
            height="28.08"/>
      <rect id="glass-3"
            data-name="glass"
            class="cls-9"
            :x="doorLeftWidth1 + 39.92"
            :y="doorTopHeight1 + 222.88"
            width="59.99"
            height="18.24"/>
      <rect id="inox-4"
            v-if="showInox"
            data-name="inox"
            class="cls-10"
            :x="doorLeftWidth1 + 35.01"
            :y="doorTopHeight1 + 162.39"
            width="69.83"
            height="27.84"/>
      <rect id="glass-4"
            data-name="glass"
            class="cls-11"
            :x="doorLeftWidth1 + 39.92"
            :y="doorTopHeight1 + 167.19"
            width="59.99"
            height="18.24"/>
      <g v-if="showMolding">
        <g id="Molding-1" data-name="Molding">
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 100.25} ${doorTopHeight1o + 55.3}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1o + 55.3}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1o + 50.91}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1o + 50.91}
              ${doorLeftWidth1 + 100.25} ${doorTopHeight1o + 55.3}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1o + 74.57}
              ${doorLeftWidth1 + 100.37} ${doorTopHeight1o + 74.57}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1o + 78.95}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1o + 78.95}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1o + 74.57}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1o + 55.3}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1o + 74.57}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1o + 78.95}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1o + 50.91}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1o + 55.3}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 100.31} ${doorTopHeight1o + 74.57}
              ${doorLeftWidth1 + 100.2} ${doorTopHeight1o + 55.3}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1o + 50.91}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1o + 78.95}
              ${doorLeftWidth1 + 100.31} ${doorTopHeight1o + 74.57}
            `"/>
          <polygon
            class="Molding-T"
            :points="`
              ${doorLeftWidth1 + 100.25} ${doorTopHeight1o + 55.3}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1o + 55.3}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1o + 50.91}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1o + 50.91}
              ${doorLeftWidth1 + 100.25} ${doorTopHeight1o + 55.3}
            `"/>
          <polygon
            class="Molding-B"
            :points="`
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1o + 74.57}
              ${doorLeftWidth1 + 100.37} ${doorTopHeight1o + 74.57}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1o + 78.95}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1o + 78.95}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1o + 74.57}
            `"/>
          <polygon
            class="Molding-L"
            :points="`
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1o + 55.3}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1o + 74.57}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1o + 78.95}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1o + 50.91}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1o + 55.3}
            `"/>
          <polygon
            class="Molding-R" 
            :points="`
              ${doorLeftWidth1 + 100.31} ${doorTopHeight1o + 74.57}
              ${doorLeftWidth1 + 100.2} ${doorTopHeight1o + 55.3}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1o + 50.91}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1o + 78.95}
              ${doorLeftWidth1 + 100.31} ${doorTopHeight1o + 74.57}
            `"/>
        </g>
        <g id="Molding-2" data-name="Molding">
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 100.25} ${doorTopHeight1t + 111.36}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1t + 111.36}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1t + 106.98}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1t + 106.98}
              ${doorLeftWidth1 + 100.25} ${doorTopHeight1t + 111.36}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1t + 130.63}
              ${doorLeftWidth1 + 100.37} ${doorTopHeight1t + 130.63}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1t + 135.01}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1t + 135.01}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1t + 130.63}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1t + 111.36}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1t + 130.63}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1t + 135.01}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1t + 106.98}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1t + 111.36}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 100.31} ${doorTopHeight1t + 130.63}
              ${doorLeftWidth1 + 100.2} ${doorTopHeight1t + 111.36}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1t + 106.98}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1t + 135.01}
              ${doorLeftWidth1 + 100.31} ${doorTopHeight1t + 130.63}
            `"/>
          <polygon
            class="Molding-T"
            :points="`
              ${doorLeftWidth1 + 100.25} ${doorTopHeight1t + 111.36}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1t + 111.36}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1t + 106.98}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1t + 106.98}
              ${doorLeftWidth1 + 100.25} ${doorTopHeight1t + 111.36}
            `"/>
          <polygon
            class="Molding-B"
            :points="`
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1t + 130.63}
              ${doorLeftWidth1 + 100.37} ${doorTopHeight1t + 130.63}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1t + 135.01}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1t + 135.01}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1t + 130.63}
            `"/>
          <polygon
            class="Molding-L"
            :points="`
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1t + 111.36}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1t + 130.63}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1t + 135.01}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1t + 106.98}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1t + 111.36}
            `"/>
          <polygon
            class="Molding-R"
            :points="`
              ${doorLeftWidth1 + 100.31} ${doorTopHeight1t + 130.63}
              ${doorLeftWidth1 + 100.2} ${doorTopHeight1t + 111.36}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1t + 106.98}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1t + 135.01}
              ${doorLeftWidth1 + 100.31} ${doorTopHeight1t + 130.63}
            `"/>
        </g>
        <g id="Molding-3" data-name="Molding">
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 100.25} ${doorTopHeight1r + 167.22}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1r + 167.22}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1r + 162.84}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1r + 162.84}
              ${doorLeftWidth1 + 100.25} ${doorTopHeight1r + 167.22}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1r + 186.49}
              ${doorLeftWidth1 + 100.37} ${doorTopHeight1r + 186.49}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1r + 190.88}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1r + 190.88}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1r + 186.49}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1r + 167.22}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1r + 186.49}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1r + 190.88}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1r + 162.84}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1r + 167.22}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 100.31} ${doorTopHeight1r + 186.49}
              ${doorLeftWidth1 + 100.2} ${doorTopHeight1r + 167.22}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1r + 162.84}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1r + 190.88}
              ${doorLeftWidth1 + 100.31} ${doorTopHeight1r + 186.49}
            `"/>
          <polygon
            class="Molding-T"
            :points="`
              ${doorLeftWidth1 + 100.25} ${doorTopHeight1r + 167.22}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1r + 167.22}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1r + 162.84}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1r + 162.84}
              ${doorLeftWidth1 + 100.25} ${doorTopHeight1r + 167.22}
            `"/>
          <polygon
            class="Molding-B"
            :points="`
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1r + 186.49}
              ${doorLeftWidth1 + 100.37} ${doorTopHeight1r + 186.49}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1r + 190.88}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1r + 190.88}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1r + 186.49}
            `"/>
          <polygon
            class="Molding-L"
            :points="`
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1r + 167.22}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1r + 186.49}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1r + 190.88}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1r + 162.84}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1r + 167.22}
            `"/>
          <polygon
            class="Molding-R"
            :points="`
              ${doorLeftWidth1 + 100.31} ${doorTopHeight1r + 186.49}
              ${doorLeftWidth1 + 100.2} ${doorTopHeight1r + 167.22}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1r + 162.84}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1r + 190.88}
              ${doorLeftWidth1 + 100.31} ${doorTopHeight1r + 186.49}
            `"/>
        </g>
        <g id="Molding-4" data-name="Molding">
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 100.25} ${doorTopHeight1f + 223.54}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1f + 223.54}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1f + 219.16}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1f + 219.16}
              ${doorLeftWidth1 + 100.25} ${doorTopHeight1f + 223.54}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1f + 242.82}
              ${doorLeftWidth1 + 100.37} ${doorTopHeight1f + 242.82}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1f + 247.2}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1f + 247.2}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1f + 242.82}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1f + 223.54}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1f + 242.82}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1f + 247.2}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1f + 219.16}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1f + 223.54}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 100.31} ${doorTopHeight1f + 242.82}
              ${doorLeftWidth1 + 100.2} ${doorTopHeight1f + 223.54}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1f + 219.16}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1f + 247.2}
              ${doorLeftWidth1 + 100.31} ${doorTopHeight1f + 242.82}
            `"/>
          <polygon
            class="Molding-T"
            :points="`
              ${doorLeftWidth1 + 100.25} ${doorTopHeight1f + 223.54}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1f + 223.54}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1f + 219.16}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1f + 219.16}
              ${doorLeftWidth1 + 100.25} ${doorTopHeight1f + 223.54}
            `"/>
          <polygon
            class="Molding-B"
            :points="`
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1f + 242.82}
              ${doorLeftWidth1 + 100.37} ${doorTopHeight1f + 242.82}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1f + 247.2}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1f + 247.2}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1f + 242.82}
            `"/>
          <polygon
            class="Molding-L"
            :points="`
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1f + 223.54}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1f + 242.82}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1f + 247.2}
              ${doorLeftWidth1 + 35.25} ${doorTopHeight1f + 219.16}
              ${doorLeftWidth1 + 40.04} ${doorTopHeight1f + 223.54}
            `"/>
          <polygon
            class="Molding-R"
            :points="`
              ${doorLeftWidth1 + 100.31} ${doorTopHeight1f + 242.82}
              ${doorLeftWidth1 + 100.2} ${doorTopHeight1f + 223.54}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1f + 219.16}
              ${doorLeftWidth1 + 105.16} ${doorTopHeight1f + 247.2}
              ${doorLeftWidth1 + 100.31} ${doorTopHeight1f + 242.82}
            `"/>
        </g>
      </g>
    </g>
  </g>
</template>


<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
    'showVariant',
    'laminationId',
    'showMolding',
    'doorSolidColor',
  ],
  data() {
    return {
      inoxOffset: 22.18,
      leafOffset: 6.4,
      leftOffset: 87,
    }
  },
  computed: {
    inoxY() {
      return this.showBg ?
        this.doorTopHeight + this.inoxOffset :
        this.doorTopHeight + this.leafOffset
    },
    inoxH() {
      return this.showBg ?
        this.doorHeight - this.inoxOffset * 2 :
        this.doorHeight - this.leafOffset * 2
    },
    inoxW() {
      return this.showBg ?
        this.doorWidth - this.inoxOffset - this.leftOffset / 2 :
        this.doorWidth - this.leafOffset - this.leftOffset / 2
    },
    inoxTop() {
      return this.showBg ?
        this.doorTopHeight + this.inoxOffset :
        this.doorTopHeight + this.leafOffset
    },
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 148
    },
    doorTopHeight1o() {
      return this.doorTopHeight + this.doorHeight/2 - 148
    },
    doorTopHeight1t() {
      return this.doorTopHeight + this.doorHeight/2 - 148.5
    },
    doorTopHeight1r() {
      return this.doorTopHeight + this.doorHeight/2 - 148.5
    },
    doorTopHeight1f() {
      return this.doorTopHeight + this.doorHeight/2 - 149
    },
  },
}
</script>
